.contenedorCentro{
    display: flex;
    justify-content: center;
    align-items: center;
}

.contenedorCentro input {
    margin: 0 5px;
}

.imagenCompleta{
    height: 70% !important;
}