.divContenedorImagen{
  position: relative;
  display: inline-block;
}

.botonEliminarImagen{
  padding: 1px !important;
  background-color: #f80000 !important;
  border: none !important;
  display: flex;
  justify-content:center;
  align-items: center;
  width: 21px !important;
  height: 21px !important;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.imagenReporteEvidencia{
  width: 110px ;
  height: 80px ;
  cursor: pointer ;
  display: block;
}

@media screen and (max-width: 375px) {
  .botonVerApi {
      margin-bottom: 10px !important;
  }
}
.botonVerApi {
  margin-right: 15px !important;
}

.contenedorComentario{
  position: relative !important;
}

.contenedorIconoComentario{
  display: flex;
  justify-content: center;
}

@media (max-width: 480px) {
  .contenedorIconoComentario{
    display: none;
  }
}

.encabezadoComentario{
  display: inline !important;
}

.encabezadoComentario span{
  padding-left: 0 !important;
}

.nombreUsuario{
  background-color: transparent;
  font-size: 15px;
  color: #233A4E;
  font-weight: bolder;
}

.fechaGeneracionReporte{
  background-color: transparent;
  font-size: 13px;
  color: #646566;
}

.divComentario p{
  font-size: 14px;
  margin-bottom: 1px !important;
  padding-left: 0px !important;
}

.textoAccion{
  background-color: transparent;
  font-size: 13px;
  padding-left: 0px !important;
  color: #646566;
  font-style: italic;
}

.dropdownOpcionesComentario {
  position: absolute;
  top: 0; 
  right: 0; 
  margin-right: 10px;
}

.dropdownOpcionesComentario :hover{
 cursor: pointer;
}

.divContenedorBotonComentar {
  position: relative;
}
  
.divContenedorBotonComentar Button{
  position: absolute;
  bottom: 10px;
  right: 10px;
}















  
  
  