.botonMasInformacion{
    display: flex;
    align-items: center;
    width: fit-content;
    color:#0d6efd !important;
    border-bottom: 1px solid #0d6efd !important;
    cursor: pointer;
}

.botonMasInformacion a{
    text-decoration: none !important;
    margin-right: 5px !important;
}

.botonLimpiarBusqueda {

}