/* Animaciones de cards */
.animacionCardProyecto{
    animation:scale-up-center 0.4s
} 

@keyframes scale-up-center{ 
    0%{
        transform:scale(.5)
    } 
    100%{
        transform:scale(1)
    } 
}

/* Diseño de cards del menú del Dashboard*/
.cardProyecto{
    background-color: rgb(96, 124, 149, 0.3);
    border-radius: 10px 10px 13px 13px !important;
}

.divDescripcionProyecto{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
}

.nombreProyecto{
    font-size: 19px;
    font-weight: bold;
}

.fechaCreacion{
    font-size: 12px;
    color: gray;
    margin-top: -10px;
}

.divContenedorApis{
    display: flex;
    margin-top: 16px !important;
}

.logoProyecto{
	width:80px !important;
    height: 80px !important;
    border-radius: 15px;  
}

.apisInformacion{
    display:inline-flex !important;
    align-items: center !important;
    margin-bottom: 3px;
}

.botonVerProyecto{
    width: 100% !important;
    border-bottom: px solid rgb(175, 175, 175) !important;
    border-radius: 0px 0px 10px 10px!important;
}

