.logoProyectoDetalles{
    width:120px;
}

.usuarioContenedor.badge.bg-primary{
    background-color: rgba(21, 76, 134, 0.644) !important;
    border-radius: 4px !important;
    height: 23px !important;
    width: fit-content;
    display: flex;
    align-items: center;
    margin-bottom: 3px !important;
}

.usuarioContenedor.badge.bg-primary span {
    text-decoration: none !important;
    color: black;
    font-size: 14px;
}
.usuarioContenedor.badge.bg-primary .eliminarUsuario {
    margin-left: 3px ;
    cursor: pointer;
}



