.inputTextareaModal.form-control {
    border-bottom:  1px solid #999 !important;
    padding: 10px;
    border-radius: 0;
    outline: none !important;
}

.botonAgregar{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    border:  1px solid #919191 !important;
    border-radius: 0;
    padding: 7px;
    outline: none !important;
    background-color: transparent !important;
    font-size: 14px;
}

@media screen and (max-width: 991px) {
    .divBotonAgregarApi{
        margin-top: 0 !important;
    }
}
