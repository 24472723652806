/* Diseño de navbar */
.navbarPrincipal{
    background-color: #233A4E;
}

.navbarPrincipal .navbar-nav {
    margin-left: auto;
    padding-right: 10px;
}

.navbarBrand{
    color: white;
    font-size: 25px;
	font-weight: bold;
}

.navbarBrand:hover{
    color: white;
    font-size: 25px;
	font-weight: bold;
}

@media screen and (min-width: 579px) {
    .navContenidoMenu{
        display: flex !important;
        align-items: center !important;
    }
}

.navlinkNavbar{
    color: #ffffff;
    padding: 0.5rem 1rem;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

.navlinkNavbar:hover {
    color: white;
    background-color: #314250;
    border-radius: 10px;
    
  }
  
.dropdown-menu { 
    background-color: #435d74;
}

.dropdown-menu .dropdown-item .navlinkDropdownitemNavbar{ 
    color: white !important;
    text-decoration: none;
}

.dropdown-menu .dropdown-item{ 
   color: white !important;
}

.dropdown-menu .dropdown-item:hover{ 
    color: white;
    background-color: #314250;
}

.badge.badgeNotificacion{
    margin-left: 2px !important;
    border-radius: 50% !important;
    padding-left: 7px !important;
}
