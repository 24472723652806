.divCentradoVerticalmente{
  display: flex !important;
  align-items: center !important;
}

.border{
  border: 1px solid red !important;
}

.divBreadcrumbPrincipal{
  display: flex;
  justify-content: space-between;
}

.divRegresar{
  float: right !important; 
}

.regresarIcono{
  color: #0D6EFD;
}

.regresarTexto{
  color: #0D6EFD;
  cursor:pointer;
  text-decoration: none;
}

@media(max-width: 480px){
  .regresarTexto {
    display: none;
  }
}

/* Diseño de main, el contenedor principal de la mayoria de los componentes */ 
main{
  background-color: #EEEEEE !important;
  padding: 15px 40px 20px 40px !important;
  width: 100% !important;
  height: 90vh !important;
  overflow-y: auto !important;
  overflow-x: auto !important;
}

main::-webkit-scrollbar {
  width: 8px;
  background-color: #273544; 
}

main::-webkit-scrollbar-thumb {
  background-color: #999; 
  border-radius: 3px;
}

@media screen and (max-width: 768px) {
  main{
    padding: 15px 20px 20px 20px !important;
  }
} 

/* Diseño del breadcrumb */
.breadcrumbPrincipal{
  display: flex;
  align-items: center;
}

.breadcrumbPrincipal svg {
  margin-right: 5px;
}

.breadcrumbPrincipal span {
  font-size: 23px;
}

.breadcrumbSecundario{
  font-size: 14.5px;
  color: grey !important;
}

/* Diseño de secciones */
.cardSeccion {
  background-color: #ffffff;
  padding: 0px 0px 0px 0px ;
  border-radius: 3px !important;
  box-shadow: 1px 1px 1px grey;
}

.cardSeccionHeader{
 background-color: white !important;
} 

.contenedorTituloSeccionDetalles{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contenidoTituloSeccion{
  display: flex;
  align-items: center;
}

.divTituloSeccion{
  display: flex;
  align-items: center;
}

.tituloSeccion {
  color:#233A4E;
  font-size: 21px;
  font-weight: 600;
  padding-left: 3px;
}

.cardSeccion.seccionDerecha {
  height: 100% !important;
} 

.tituloEncabezado{
  font-size: 16px;
  font-weight: 700;
}

.tituloProyecto{
  font-size: 15px;
}

/* Diseño de buscador de proyectos, modulos, submódulos, apis, reportes y solicitudes */
.inputBuscador.form-control {
  color: #233A4E;
  border: 1px solid #999 !important;
  border-right: none !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  font-size: 14px !important;
}

.inputBuscador.form-control:focus {
  box-shadow: none !important;
}

.inputBuscador.form-control::placeholder {
  color:#233A4E;
}

.logoBuscador{
  background-color: transparent !important;
  border-radius: 0 !important;
  border: 1px solid #999 !important;
  border-left: none !important;
}


/* Diseño de headers */
.tituloHeaders{
  padding-left: 0px;
  font-size: 16px;
  font-weight: 700;
}

.divContenedorPrincipalCodigo{
  display: flex !important;
  padding-right: 20px !important;
  background-color: #2e4052;
  height: 250px !important;
  overflow-y:scroll !important;
  overflow-x: hidden
} 

.divContenedorPrincipalCodigo::-webkit-scrollbar {
  width: 8px;
}

.divContenedorPrincipalCodigo::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 3px;
}

.divContenedorPrincipalCodigo::-webkit-scrollbar-thumb:hover {
  background-color: #666; 
}

.divContenedorPrincipalCodigo::-webkit-scrollbar-track {
  background-color: #273544;
}

.divContenedorLineasCodigo{
  width: fit-content !important;
  text-align: end;
  padding-left: 8px;
  color: #999;
  font-size: 14px;
  user-select: none;
  height: auto; 
}

.divContenedorCodigo {
  padding-left: 13px;
  color: white;
  font-size: 16px;
  width: 100% !important;
  height: 100% !important;
}

/* Diseño de tabla de información general de proyecto, modulo y submódulo */
.tableContenidoDetalles{
 width: 100% !important;
}

.trTabla{
  height: 30px !important;
}

@media screen and (max-width: 375px) {
  .botonConIcono {
      display:inline-flex !important;
      justify-content: center !important;
      align-items: center !important;
      padding: 7px 30px !important;
      width: 100%;
  }
}
.botonConIcono {
  display:inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 7px 30px !important;
}

/* Diseño de cards de modulos, apis y submodulos */
.cardModSubApi{
  background-color: #DEE2D7 !important;
}

.cardBodyModSubApi{
  padding: 0px !important;
  margin: 10px !important;
}

.divTituloModSubApi{
  font-size: 16px;
  font-weight: bold;
}

.apisInformacion{
  display:inline-flex !important;
  align-items: center !important;
  margin-bottom: 3px;
}

.apisInformacion span{
  margin-left: 4px;
  font-size: 15px;
  color: grey !important;
}

.divContenedorBotonVer{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.botonVer {
  width: 100%;
  font-size: 15px !important;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #1132ec !important;
  color: white !important;
  border-radius: 20px !important;
  border: none !important;
  cursor: pointer !important;
}

.botonCrearModSubApi{
  width: 100% !important;
  display:inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-decoration: none;
}

@media screen and (min-width: 576px) {
  .divContenedorBotonVer{
      display: flex !important;
      align-items: center !important;
      justify-content: end !important;
  }

  .botonCrearModSubApi{
    position: absolute;
    right: 11px;
    display:inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: fit-content !important;
  }

  .botonVer {
      width: fit-content;
      font-size: 15px !important;
      display: inline-flex !important;
      justify-content: center !important;
      align-items: center !important;
      background-color: #1132ec !important;
      color: white !important;
      border-radius: 20px !important;
      border: none !important;
      cursor: pointer !important;
  }
} 

@media screen and (min-width: 768px) {
  /* Div que contiene los botones de crear proyecto, módulo, submódulo, solicitudes y api */
  .divContenedorBotones{
    position: relative !important;
  }

  /* Div que contiene el botón crear proyecto */
  .divBotonCrear{
    position: absolute;
    right: 11px;
  }
} 

@media screen and (max-width: 769px) {
   /* Div que contiene el botón crear proyecto */
  .divContenedorBotones{
    margin-top: 14px !important;
  }
} 

/* Botón de crear proyecto, módulo, submódulo, api, reporte y solicitud con ícono */
.botonIconoCrear{
  display:inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-right: 5px;
  width: 100% !important;
}

/* Diseño de Tablas de catálogo de reportes y solicitudes*/  
.tablaListaElementos td{
  font-size: 14px !important;
}

.titulosTabla{
  background-color: #607c95;
}

/* Diseño del tooltip */
.toolTipDiseño {
  border: solid .5px #808080 !important;
  background-color: #ffffff !important;
  color: #000000 !important;
}

/* Diseño de div de descripción de proyecto, modulo, submódulo, api, reporte y solicitudes */
.divDescripcion h1{
  font-size: 22px !important;
}

.divDescripcion h2{
  font-size: 20px !important;
}

.divDescripcion h3{
  font-size: 18px !important;
}

.divDescripcion p{
  font-size: 15px !important;
}

.divDescripcion li{
  font-size: 15px !important;
}

.divDescripcion pre.ql-syntax{
  padding-left: 10px !important;
  background-color: #233A4E !important;
  color: white !important;
  border-radius: 5px !important;
}

/* Diseño de inputs y labels de formularios de modales y componentes */
.modalHeader{
  background-color:#233A4E;
  padding: 30px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.labelTituloModal {
  font-size: 22px !important;
  color: #fff !important; 
  margin-top: 5px !important;
  font-weight: bolder !important;
}

.iconoCerrarModal{
  cursor: pointer;
  position: absolute;
  font-size: 30px;
  color: #fff;
  top: 15px;
  right: 15px;
}

.divContenedorModal {
  padding: 15px !important;
  padding-bottom: 10px !important;
}

.labelInputModal {
  background-color: transparent;
  font-size: 15px;
  color: #233A4E;
  font-weight: bolder;
} 

.spanObligatorio{
  color: red !important;
  font-size: 17px !important;
}

.spanOpcional{
  font-style: italic;
  font-size: 15px;
  color: rgb(78, 76, 76);
  font-weight: 500;
}

.inputModal.form-control {
  border:  1px solid #999 !important;
  padding: 8px;
  font-size: 15px;
  outline: none !important;
  border-radius: 0;
  width: 100% !important;
  background-color: transparent !important;
}

.textAreaDescripcionModal{
  height: 304px !important;
  resize: none !important; 
}

.textAreaDescripcionModal h1{
  font-size: 22px !important;
}

.textAreaDescripcionModal h2{
  font-size: 20px !important;
}

.textAreaDescripcionModal h3{
  font-size: 18px !important;
}

.textAreaDescripcionModal p{
  font-size: 15px !important;
}

.textAreaDescripcionModal li{
  font-size: 15px !important;
}

.textAreaDescripcionModal pre.ql-syntax{
  padding-left: 10px !important;
  background-color: #233A4E !important;
  color: white !important;
  overflow: visible !important;
  width: fit-content !important;
}

.textAreaHeadersModal{
  height: 347px !important;
  border-radius: 0;
  font-size: 14px !important;
}

.contenedorContadorCaracteres{
  display: flex;
  justify-content: end;
}

.contenedorMensajeError{
  display: flex;
  justify-content: start;
}

.contadorCaracteres{
  padding-right: 8px;
  color: #999;
  font-size: 14px;
}

.mensajeError{
  margin-left: 0px !important;
  color: #f80000 !important;
  font-size: 14px !important;
}

.divContenedorBotonesModal {
  display: flex;
  margin-bottom: 20px;
  margin-left: 10px;
  max-width: 400px !important;
  margin: auto;
}

.btnCancelModal {
  border-radius: 5px;
  background-color: #ece8e0 !important;
  color: #000000 !important;
  margin-right: 10px !important;
  width: 100% !important;
  border: 0px !important;
}

.btnAceptarModal {
  border-radius: 5px;
  color: white !important;
  width: 100% !important;
  margin-left: 10px !important;
  padding: 0px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
  background-color: #233A4E !important;
}

/* Badge de importancia o prioridad de reportes y solicitudes  */
.badgeUrgente.badge.bg-secondary{
  background-color: #FF2525 !important;
  font-size: 14px;
  width: 80px;
}

.badgeMuyAlta.badge.bg-secondary{
  background-color: #FF8000 !important;
  font-size: 14px;
  width: 80px;
}

.badgeAlta.badge.bg-secondary{
  background-color: #FFC107 !important;
  font-size: 14px;
  width: 80px;
}

.badgeMedia.badge.bg-secondary{
  background-color: #2FF35B !important;
  font-size: 14px;
  width: 80px;
}

.badgeBaja.badge.bg-secondary{
  background-color: #28A745 !important;
  font-size: 14px;
  width: 80px;
}

/* Diseño de tipo de solicitud de api en modulo, submodulo y catálogo de apis */
.badgePost.badge.bg-secondary{
  background-color: #28A745 !important;
  font-size: 12px;
  width: 92px !important;
}

.badgeGet.badge.bg-secondary{
  background-color: #0D6EFD !important;
  font-size: 12px;
  width: 92px !important;
}

.badgePut.badge.bg-secondary{
  background-color: #FFC107 !important;
  font-size: 12px;
  width: 92px !important;
}

.badgeDelete.badge.bg-secondary{
  background-color: #FF2525 !important;
  font-size: 12px;
  width: 92px!important;
}