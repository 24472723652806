.infoFecha{
    margin-left: 5px !important;
    border-radius: 7px !important;
    width: 18px;
    height: 17px;
    background-color: #2e4052; 
    display: flex;
    justify-content: center;
    align-items: center; 
}
