.divContenedorInformacionUsuario{
    display: flex;
    flex-direction: column;
}

.informacionUsuario{
    font-size: 16px;
    margin-top: -9px !important;
}

.divContenedorImagenPerfilUsuario{
    display: flex;
    justify-content: center;
}

.imagenPerfilUsuarioModal{
    width:120px !important;
    height:120px !important;
    border-radius: 25px !important;
    object-fit: contain;
    border: 1px solid rgb(156, 147, 147) !important;  
}








  