.divLogin{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.cardLogin{
    border: none;
}

.divLogoLogin{
    border-radius: 20px 0 0 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #233A4E;
    border: 1px solid #233A4E;
}  

.divContenedorApibox{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.divContenedorApibox a{
    text-decoration: none;
    color: white !important;
    font-size: 25px;
	font-weight: bold;
}
.divContenedorPropiedad{
    position: absolute;
    bottom: 10px;
}
.divContenedorPropiedad a{
    color: white !important;
    font-style: italic;
    font-size: 15px;

}
.divContenedorTextoLogin a{
    font-style: italic;
    color: white !important;
}

.divFormularioLogin{
    background-color: rgba(35, 58, 78, 0.04); 
    border-radius: 0 20px 20px 0 !important ;
    border: 1px solid  #233A4E;
    padding: 45px 15px 45px 15px;
}

.divIdentificate{
    text-align: center;
    font-size: 25px;
    font-weight: bolder;
    color: #233A4E;
    margin-bottom: 30px !important;
}

.iconoInput{
    background-color: #233A4E;
    border:  1px solid #233A4E !important;
}

.inputLogin.form-control {
    border:  1px solid #999 !important;
    font-size: 15px !important;
    padding: 8px;
    outline: none !important;
    border-radius: 0;
    width: 70% !important;
    background-color: transparent !important;
}

.inputClave{
    border-top: 1px solid #999 !important;
    border-left: 1px solid #999 !important;
    border-bottom: 1px solid #999 !important;
    border-right:none !important;
    padding: 8px;
    font-size: 15px !important;
    outline: none !important;
    border-radius: 0;
    width: 70% !important;
    background-color: transparent !important;
}

@media screen and (max-width: 600px){
    .divLogoLogin{
        display: none !important;
    }  
    .divFormularioLogin{
        background-color: transparent; 
        border: none;
        padding: 40px 15px 40px 15px;
        width: 100vh;
    }
}

.divBotonIniciarSesión{
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.divBotonIniciarSesión Button{
   width: 80%;
   font-weight: bolder;
}



