/* Diseño de textarea de descripción de crear proyecto y documentar api */
.textAreaDescripcion h1{
    font-size: 22px !important;
}

.textAreaDescripcion h2{
    font-size: 20px !important;
}

.textAreaDescripcion h3{
    font-size: 18px !important;
}

.textAreaDescripcion p{
    font-size: 15px !important;
}

.textAreaDescripcion li{
    font-size: 15px !important;
}

.textAreaDescripcion pre.ql-syntax{
    padding-left: 10px !important;
    background-color: #233A4E !important;
    color: white !important;
    overflow: visible !important;
    width: fit-content !important;
}

.textAreaHeaders{
    height: 377px !important;
    border:  1px solid #b8b8b8 !important;
    border-radius: 0 !important;
    padding: 7px !important;
    outline: none !important;
    background-color: transparent !important;
    font-size: 14px !important;
}

.contenedorErroresDescripcion{
    padding-top: 45px!important;
}

@media screen and (max-width: 423px) {
    .contenedorErroresDescripcion{
        padding-top: 70px!important;
    }

    .divContenedorTextAreaHeaders{
        padding-top: 35px;
    }
    
}

.logoProyectoImg{
    margin-left: 5px !important;
    border-radius: 7px !important;
    width: fit-content;
    height: 17px;
    background-color: #2e4052; 
    display:inline-flex;
    justify-content: center;
    align-items: center; 
}

/* Diseño de contenedor y botones de guardar proyecto y documentar api */
.divContenedorBotonesGuardar{
    display: flex ;
    justify-content: flex-end ;
}

.botonGuardarProyecto{
    margin-left: 15px !important;
}