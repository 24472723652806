/* Diseños generales */
.dropdownOpcionesDetalles :hover{
  cursor:  pointer;
}

/* Diseño de documentación api*/
.divContenedorNombreApi{
  text-align: start;
}

.tituloNombreApi{
  font-size: 20px;
  font-weight: 700;
  margin-right: 3px !important;
}

.badgeReportada{
  cursor:  pointer;
  display: inline-block;
}

.badgeSolicitud{
  cursor:  pointer;
  display: inline-block;
}

.divContenedorBotonReporteInfo{
  display: inline-block;
}

.botonReporteInfo{
  border-radius: 10px !important;
  width: 18px;
  height: 17px;
  background-color: #dc3545 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divContenedorUltimaModificacion{
  display: flex;
  align-items: center;
}

.infoUltimaModificacion{
  margin-left: 5px !important;
  border-radius: 7px !important;
  width: 18px;
  height: 17px;
  background-color: #2e4052; 
  display: flex;
  justify-content: center;
  align-items: center; 
}

.tooltipContenido{
  font-size: 13px !important;
}

.botonIconoCopiar{
    background-color: transparent;
    border: none;
}

.botonIconoCopiar:hover{
    background-color: transparent;
    border: none;
}

.botonIconoCopiar:click{
    background-color: transparent !important;
    border: none !important;
}

.divContenedorUrlApi{
    display: flex;
    align-items: center;
}

.cardUrlApi{
   background-color:#9C9C9C;
   width: 100%;
   height: 80% !important;
   justify-content: center;
}

/* Diseño de tipo de solicitud en documentación */
.badge.bg-secondary{
  font-size: 15px;
  margin-right:8px;
  border-radius: 8px !important
}

.badgeGetApi.badge.bg-secondary{
  background-color: #0D6EFD !important;
}

.badgePostApi.badge.bg-secondary{
  background-color: #28A745 !important;
}

.badgePutApi{
  background-color: #FFC107 !important;
}

.badgeDeleteApi{
  background-color: #FF2525 !important;
}

.divEJemplo{
margin-top: 7px !important;
}

/* Diseño de formato de cuerpo en modulo, submódulo y documentación */
.badge.bg-success{
  margin-left: 10px !important;
  font-size: 12px;
  width: 92px;
  border-radius: 8px !important;
  justify-content: center;
}

.badgeJson{
  background-color: #FFC107 !important;
}

.badgeFormDataEncode{
  background-color: #0D6EFD !important;
}

.badgeText{
  background-color: #28A745 !important;
}

.badgeSinFormato{
  background-color: #6d756f !important;
}

.spanFormatoCuerpo{
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

/* Diseño de notas de api */
.cardNotaApi{
  display: flex;
  flex-direction: column;
  background-color: #2e4052;
  border: 1px solid #2e4052;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 4px;
  margin-top: 5px;
  margin-right: 5px;
  
}

.contenedorNumeroNota{
  display: flex;
  justify-content: space-between;
}

.numeroNota{
  font-size: 15px;
  color: white;
  font-weight: bolder;
  margin-bottom: 3px;
}

.divNota p{
  font-size: 13px;
  color: white;
  line-height: 1.2;
}

.divNota li{
  font-size: 13px;
  color: white;
  line-height: 1.2;
}

.nombreUsuarioNota{
  text-align: right;
  font-size: 14px;
  color: white;
  font-weight: bolder;
}

.colaboradores{
  display: flex;
  flex-wrap: wrap;
}

.usuarioContenedorColaborador.badge.bg-primary{
  background-color: rgba(21, 76, 134, 0.644) !important;
  border-radius: 4px !important;
  height: 23px !important;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-left: 5px !important;
}

.usuarioContenedorColaborador.badge.bg-primary span {
  text-decoration: none !important;
  color: black;
  font-size: 14px;
}
.usuarioContenedorColaborador.badge.bg-primary .eliminarUsuario {
  margin-left: 3px ;
  cursor: pointer;
}
