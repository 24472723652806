.divContenedorUbicacion{
    display: grid;
    align-items: center;
}

.divTituloUbicacion{
    font-size: 16px;
    font-weight: bold;
}

.divUbicacionApi{
    font-size: 14px;
    color: gray;
}
/* Diseños filtros*/

.formSelectFiltro{
    display: inline-flex;
    width: 100% !important;
}

.labelFiltro{
    font-size: 14px;
    color: #233A4E;
    font-weight: bolder;
    margin-top: 5px !important;
}

.selectFiltro{
    margin-left: 5px;
    background-color: transparent !important;
    height: 35px !important;
    width: 100% !important;
    font-size: 14px !important;
    border-radius: 0 !important;
}

.inputBuscador2{
    border-right: none;
    background-color: transparent !important;
    border-radius: 0 !important;
    height: 35px !important;
    font-size: 14px !important;
}

.inputBuscador2.form-control:focus {
    box-shadow: none !important;
    border-color: #ced4da !important;
}

.logoBuscador{
    background-color: transparent !important;
    border-radius: 0 !important;
    border-left: none !important;
}

.botonLimpiarBusqueda{
    height: 35px !important;
    width: 100%;
    font-size: 14px !important;
    font-weight: bolder;
}

.selectItemsPorPagina{
    background-color: transparent !important;
    height: 28px !important;
    width: fit-content !important;
    font-size: 14px !important;
    border-radius: 0 !important;
    margin-top: 3px !important;
}