.titulosTablaApi{
  background-color: #607c95;
  font-size: 14px !important;

}

.divBotonFinalizar{
  display: flex;
  align-items: center;
}

.botonAccionSolicitud{
  display:inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-size: 14px;
}

.iconoBotonFinalizar{
  margin-left: 5px !important;
}

.tdBotonesAcciones{
  display: flex;
  align-items: center;
}

.tdBotonesAcciones button {
  margin-right: 5px; 
}












  
  
  