
.scale-up-top-left{animation:scale-up-top-left 0.3s; } @keyframes scale-up-top-left{0%{transform:scale(.5);transform-origin:top left}100%{transform:scale(1);transform-origin:top left}}

.scale-down-top-left{animation:scale-down-top-left 0.8s; } @keyframes scale-down-top-left{0%{transform:scale(1);transform-origin:left top;}100%{transform:scale(.5);transform-origin:left top;}}

.inputModalBloqueado.form-control {
    border: 1px solid #999 !important;
    padding: 10px;
    background-color: #5c5a5a57;
    outline: none !important;
    border-radius: 0;
    width: 100% !important;
}

.divContenedorTextArea{
    height: 410px !important;
}

.divContenedorTextArea .textAreaDescripcion{
    height: 334px !important;
    resize: none !important; 
}

.textAreaModelo{
    height: 470px !important;
    border:  1px solid #b8b8b8 !important;
    border-radius: 0;
    padding: 7px;
    outline: none !important;
    background-color: transparent !important;
    font-size: 14px;
}

.divContenedorFormGroup{
    padding-top: 21px !important;
}

.textAreaEjemplo{
    height: 377px !important;
    border:  1px solid #b8b8b8 !important;
    border-radius: 0 !important;
    padding: 7px !important;
    outline: none !important;
    background-color: transparent !important;
    font-size: 14px !important;
}

.divAgregarEjemplo{
    padding-top: 35px !important;
}

.divContendorAgregarEjemplo{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    height: 470px !important;
    width: 100%;
    border:  1px solid #919191 !important;
    border-radius: 0;
    padding: 7px;
    outline: none !important;
    background-color: transparent !important;
    font-size: 14px;
}

.divContendorAgregarEjemplo span{
    color: gray;
    font-size: 15px;
}

.divContenedorBotonEliminarEjemplo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.botonEliminarEjemplo{
    padding: 1px !important;
    background-color: #f80000 !important;
    border: none !important;
    display: flex;
    justify-content:center;
    align-items: center;
    width: 21px !important;
    height: 21px !important;
}

.botonCancelarDocApi{
    margin-right: 8px !important;
}
